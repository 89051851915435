export default {
  props: ['prices','showPricelistLink'],

  computed: {
    dailyPrice() {
      return this.prices && this.prices.length ? this.prices.filter(item => item.setting_key === 'ONE_WEEKDAY').pop().setting_value : '';
    },
    dailyPriceWeekend() {
      return this.prices && this.prices.length ? this.prices.filter(item => item.setting_key === 'ONE_WEEKEND').pop().setting_value : '';
    },
    twoNightsWeekday() {
      return this.prices && this.prices.length ? this.prices.filter(item => item.setting_key === 'TWO_WEEKDAYS').pop().setting_value : '';
    },
    twoWeekendDays() {
      return this.prices && this.prices.length ? this.prices.filter(item => item.setting_key === 'TWO_WEEKEND_DAYS').pop().setting_value : '';
    },
    barrelSaunaPrice() {
      return this.prices && this.prices.length ? this.prices.filter(item => item.setting_key === 'BARREL_SAUNA_PRICE').pop().setting_value : '';
    },
    pricePerWeek() {
      return this.prices && this.prices.length ? this.prices.filter(item => item.setting_key === 'PRICE_PER_WEEK').pop().setting_value : '';
    },
    specialPrice() {
      return this.prices && this.prices.length ? this.prices.filter(item => item.setting_key === 'SPECIAL_PRICES').pop().setting_value : '';
    },
    specialPriceAdditional() {
      return this.prices && this.prices.length ? this.prices.filter(item => item.setting_key === 'SPECIAL_PRICE_ADDITIONAL_DAY').pop().setting_value : '';
    },
  }
}
